import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { APIProject, ProjectsService } from 'src/app/services/projects.service';
import { Auth, authState } from '@angular/fire/auth';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  userPicture = '../../../assets/img/user-img.webp';
  title?: string;
  description!: string;

  constructor(
    public router: Router,
    private auth: Auth,
    private projectService: ProjectsService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        if (event?.url.slice(event?.url.length - 8) === 'invoices' && event?.url.includes('projects')) {
          const projects: APIProject[] = await this.projectService.getProjects();
          const id = this.router.url.substring(
            this.router.url.substring(1).indexOf('/') + 2,
            this.router.url.lastIndexOf('/'),
          );
          const project = projects.find((x) => x.id === id);
          const projectName = project?.name;
          this.title = `${projectName ?? ''} Invoices`;
          if (project?.archived) this.description = 'This screen shows only archived projects.';
          if (!project?.archived)
            this.description = `Create, manage and approve ${projectName ?? ''} invoices. Configure schedule options such as requires approval, auto send, recipients and more.`;
        } else this.getTitle();
      }
    });
  }

  ngOnInit(): void {
    authState(this.auth).subscribe((authState) => {
      this.userPicture = authState?.photoURL ?? '../../../assets/img/user-img.webp';
    });
  }

  getTitle() {
    this.title = this.router.url;

    if (this.router.url === '/') {
      this.title = 'Dashboard';
      this.description = 'Get the most important reports from your company you need to know.';
    } else if (this.router.url === '/all-invoices') {
      this.title = 'All Invoices';
      this.description = 'Filter invoices by project. Find archive old invoices. Know their status and details.';
    } else {
      this.title = this.router.url.substring(1);
      this.description = '';
      switch (this.router.url) {
        case '/clients':
          this.description = 'Manage your clients. Get to know the project amount of the client.';
          break;
        case '/products':
          this.description = 'Register and manage a product with its values, icons and more.';
          break;
        case '/recipients':
          this.description = 'Email recipients to the invoices being a copy or a primary.';
          break;
        case '/projects':
          this.description = 'Manage client projects. Get to know their status and the invoices amount.';
          break;
        default:
          break;
      }
    }
  }

  async navigate(page: string) {
    await this.router.navigate([`/${page}`]);
  }

  logOut() {
    void this.auth.signOut();
    void this.router.navigate(['/login']);
  }
}
